import React from 'react'
import { useLocation } from 'react-router-dom'
import SEO from './SEO'
import PerPageMeta from './PerPageMeta'
const StarterSEO = () => {

    const location = useLocation();
    // const currentUrl = `${window.location.origin}${location.pathname}`;
    // console.log(location.pathname);
    const blog = PerPageMeta[location.pathname];
    // console.log(blog);
    return (
        <>
            {
                blog &&

                <SEO Pagetitle={blog.PageTitle} description={blog.PageDescription} keywords={blog.PageKeywords} />
            }
        </>
    )
}

export default StarterSEO