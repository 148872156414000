import React, { useEffect, useState } from "react";
import { createContext } from "react";
import axios from "axios";

export const BCone = createContext();
export const BlogContext = function ({ children }) {
  const [blogs, cblogs] = useState([]);
  useEffect(() => {
    console.log(121);
    axios
      .get("https://successful-pleat-lion.cyclic.app/get-blogs")
      .then((response) => {
        const db = response.data;
        console.log(db);
        //  saving all blogs in 'blogs' state
        const mainB = [];
        for (let i = 0; i < db.length; ++i) {
          if (db[i].blogtype === "research") mainB.push(db[i]);
        }
        cblogs(mainB);
      });
  }, []);

  return <BCone.Provider value={{ blogs, cblogs }}>{children}</BCone.Provider>;
};
