const MetaTags = {
    "/": {
        PageTitle: "Best Research Assistance for PhD & Masters | Writebing",
        PageDescription: "Need help with your PhD and Masters? Writebing provides top-notch research assistance and writing services, ensuring timely delivery and academic excellence.",
        PageKeywords: "Research Assistance, Research Help, Academic Papers, Writing Assistance, Professional Writers, Review Paper"
    },
    "/blog": {
        PageTitle: "Writebing Blog: Insights and Tips for PhD & Masters Students",
        PageDescription: "Explore Writebing's blog for insights, tips, and latest updates for PhD and Masters students. Stay informed with our expert articles and advice.",
        PageKeywords: "Blog, Research Blog, PhD Blog, Masters Blog, Academic Writing Tips, Research Tips"
    },
    "/service": {
        PageTitle: "Complete Research Assistance | Thesis/Dissertation-Writebing",
        PageDescription: "From Research proposal, thesis, dissertation, to research paper writing. Get each service with best quality from experts. Contact now and get instant discount.",
        PageKeywords: "Writing Services, Dissertation writing, Help with dissertation, Thesis Writing Services, Writing Help, Thesis Writing"
    },
    "/how-we-work": {
        PageTitle: "How Writebing Works: PhD & Masters Thesis Support",
        PageDescription: "Explore how Writebing works to support PhD and Masters students with their thesis and dissertation projects. Our process ensures quality and timely delivery.",
        PageKeywords: "Papers Research, Thesis Help, Writing Service, Academic Writers, Assignment Services"
    },
    "/contact": {
        PageTitle: "Contact Us for PhD & Masters Research Assistance | Writebing",
        PageDescription: "Reach out to Writebing today for personalized research assistance and writing services for PhD and Masters students. We're here to help all your academic needs.",
        PageKeywords: "Contact Writebing, Writing Dissertation Services, Article Writing, Research Paper Help, Research Paper Writers"
    },
    "/faq": {
        PageTitle: "Writebing FAQ: PhD and Masters Support",
        PageDescription: "Writebing answers frequently asked questions about our professional research and writing services. Learn how we help PhD and Masters students succeed.",
        PageKeywords: "Writebing FAQ, Research FAQ"
    },
};

export default MetaTags;
